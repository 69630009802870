<template>
  <div style="padding:20px">
    <header>
      <Icon type="ios-arrow-dropleft-circle"
            size='36'
            class="ico"
            title="退回"
            @click="back" />
      <Button @click="add"
              type="info">添加全景</Button>
    </header>

    <!-- 列表 -->
    <Table row-key="id"
           :columns="columns"
           :data="tabledata"
           border></Table>
    <div class="page">
      <Page show-total
            :page-size="limit"
            :total="total"
            :current="page"
            @on-change="pageChange" />
    </div>

    <!-- 全景弹窗 -->
    <!-- 上传弹窗 -->
    <Modal class="upload-modal"
           :loading="loading"
           v-model="uploadModal"
           title="新增"
           @on-ok="ok"
           @on-cancel="cancel">
      <Form ref="formValidate"
            :model="uploadData"
            :rules="ruleValidate"
            :label-width="80">
        <FormItem label="地点"
                  prop="mapName">
          <Input v-model="uploadData.mapName"
                 maxlength="30"
                 show-word-limit
                 placeholder="请输入地点"></Input>
        </FormItem>
        <FormItem label="描述"
                  prop="describe">
          <Input v-model="uploadData.describe"
                 placeholder="请输入描述"
                 type="textarea"
                 :autosize="{ minRows: 2, maxRows: 5 }"
                 maxlength="30"
                 show-word-limit></Input>
        </FormItem>
        <!-- <FormItem label="所属工程"
                  prop="category">
          <Select v-model="pid"
                  clearable
                  placeholder="">
            <Option v-for="(item) in tabledata"
                    :value="item.id"
                    :key="item.id">{{item.name}}</Option>
          </Select>
        </FormItem> -->
        <FormItem label="图片">
          <Upload :max-size="10240"
                  action
                  :format="['jpg', 'png', 'jpeg']"
                  :on-exceeded-size="handleMaxSize"
                  :on-format-error="handleFormatError"
                  :before-upload="uploadBefore">

            <Button icon="ios-cloud-upload-outline"
                    type="info">上传</Button>
          </Upload>
          <div>{{fileName}}</div>
          <p>
            {{ uploadData.image
            }}
            <Icon type="md-trash"
                  v-if="fileName"
                  size=26
                  @click="clearImage"
                  style="cursor: pointer" />
          </p>
        </FormItem>
      </Form>
    </Modal>
    <!-- 删除弹窗提示 -->
    <Modal v-model="delModel"
           @on-ok="delsure()"
           :loading="delLoading"
           title="确认删除?"
           :wdith="200">
      <p>
        <Icon type="md-alert"
              style="color: #ff9900"
              size="20" />是否删除该全景图？
      </p>
    </Modal>
  </div>
</template>

<script>
import {
  PanoramawebAdd,
  PanoramawebList,
  PanoramawebDel,
} from '@/utils/javaApi.js';

export default {
  name: 'vrMgr',
  data() {
    return {
      loading: true,
      delLoading: true,
      rowId: null,
      fileName: '',
      uploadUrl: '',
      categoryList: [],
      loadingStatus: false,
      uploadModal: false,
      limit: 7,
      page: 1,
      pid: 0,
      total: 8,
      uploadData: {
        mapName: '',
        describe: '',
        image: '',
      },
      uploadFormData: new FormData(),
      ruleValidate: {
        mapName: [
          {
            required: true,
            message: '请填写全景图地点',
            trigger: 'blur',
            min: 1,
            max: 30,
          },
        ],
        describe: [
          {
            required: true,
            message: '请填写全景图描述',
            trigger: 'blur',
            min: 1,
            max: 30,
          },
        ],
      },
      SelectID: 0,
      delModel: false,
      showDetail: false,
      tabledata: [],
      columns: [
        {
          title: '地点',
          key: 'name',
          align: 'center',
          tree: true,
        },
        {
          title: '缩略图',
          align: 'center',
          render: (h, params) => {
            return h('img', {
              attrs: {
                src: params.row.panoramaImgPath,
              },
              style: {
                width: '140px',
                height: '80px',
                margin: '5px auto',
              },
            });
          },
        },
        {
          title: '描述',
          align: 'center',
          key: 'title',
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'info',
                  },
                  on: {
                    click: () => {
                      this.edit(params.row);
                    },
                  },
                },
                '编辑'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'success',
                  },
                  style: {
                    margin: '0 10px',
                  },
                  on: {
                    click: () => {
                      this.toDetail(params.row);
                    },
                  },
                },
                '预览'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                  },
                  on: {
                    click: () => {
                      this.del(params.row.id);
                    },
                  },
                },
                '删除'
              ),
            ]);
          },
        },
      ],
    };
  },
  mounted() {
    this.uploadUrl = PanoramawebAdd();
    console.log(this.uploadUrl);
    this.getPanorame();
    this.testPath =
      'http://129.204.9.15:9024/data/wwwroot/joint_flight_java/upload_test/panorama/panorama_309/98jid8kd-a-bedroom-with-a-bed-and-a-mirror-in-a-room.jpeg';
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    del(id) {
      console.log(id);
      this.rowId = id;
      this.delModel = true;
    },
    uploadFile() {
      //   this.loadingStatus = true;
      //   setTimeout(() => {
      //     this.file = null;
      //     this.loadingStatus = false;
      //     this.$Message.success('Success');
      //   }, 1500);
    },
    uploadBefore(file) {
      console.log(file);
      this.fileName = file.name;
      this.uploadFormData.append('image', file);
      this.loadingStatus = true;
      return false;
    },
    toDetail(row) {
      let panoramaInfo = JSON.stringify(row);
      //   localStorage.setItem('panoramaInfo', panoramaInfo);
      console.log(row);
      this.$router.push({
        name: 'DetailPanorama',
        query: { id: row.id },
      });
    },
    // 获取全景图列表
    getPanorame() {
      this.$post(PanoramawebList(), {
        page: this.page,
        limit: this.limit,
        team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.total = res.data.total;
            this.tabledata = res.data.data;
            // 初始化获取
          } else {
            this.$Message.error(res.msg_customer);
          }
        })
        .catch((err) => {
          this.$Message.error(err);
        });
    },
    // 翻页
    pageChange(ind) {
      this.page = ind;
      this.getPanorame();
    },
    // 关闭弹窗
    closeImgModal() {
      //   this.viewer.destroy();
      //   this.showDetail = false;
      //   document.documentElement.style.overflowY = 'auto';
      //   this.displayPanoramadata = false;
    },
    /**
     * 上传
     */
    // 打开弹窗
    add() {
      this.editmode = false;
      this.uploadModal = true;
      this.uploadData.mapName = '';
      this.uploadData.describe = '';
      this.uploadFormData = new FormData();
      this.pid = 0;
      this.fileName = '';
    },
    // 上传格式错误
    handleFormatError() {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '请选择jpg或png格式文件',
      });
    },
    // 上传失败
    addMediaError(error) {
      this.$Message.error(error);
    },
    handleMaxSize() {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于10M.',
      });
    },
    // 清除上传文件
    clearImage() {
      this.uploadData.image = '';
      this.uploadFormData.delete('image');
      this.fileName = '';
    },
    // 上传-编辑
    ok() {
      this.uploadFormData.append('mapName', this.uploadData.mapName);
      this.uploadFormData.append('describe', this.uploadData.describe);
      this.uploadFormData.append('pid', this.pid ? this.pid : 0);
      this.uploadFormData.append('lat', null);
      this.uploadFormData.append('lng', null);
      if (!this.fileName || !this.uploadData.mapName) {
        this.$Notice.warning({
          title: '必填',
          desc: '请选择jpg或png格式文件/文件名必填',
        });
        setTimeout(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.loading = true;
          });
        }, 2000);
        return;
      }

      this.$post(PanoramawebAdd(), this.uploadFormData)
        .then((res) => {
          if (res.code === 1) {
            this.uploadModal = false;
            this.$Message.success(res.msg_customer);
            this.getPanorame();
          } else {
            this.$Message.error(res.msg_customer);
          }
          this.addLoading = false;
          this.uploadFormData = new FormData();
        })
        .catch((err) => {
          this.$Message.error(err);
          this.uploadFormData = new FormData();
        });
    },
    // 取消上传
    cancel() {
      this.clearImage();
      this.$refs['formValidate'].resetFields();
      this.uploadFormData = new FormData();
    },
    delsure() {
      this.$post(PanoramawebDel(), {
        panorama_id: this.rowId,
      })
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.$Message.success(res.msg_customer);
            this.getPanorame();
          } else {
            this.$Message.error('系统错误，请联系管理员');
          }
          this.delLoading = false;
          this.delModel = false;
        })
        .catch((err) => {
          console.log(2222);
          this.delLoading = false;
          this.$Message.error(err);
        });
    },
    // 编辑
    edit(el) {
      console.log(el);
      this.$router.push({
        name: 'EdtiPanorama',
        query: { id: el.id },
      });
      let panoramaInfo = JSON.stringify(el);
      localStorage.setItem('panoramaInfo', panoramaInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.ico {
  cursor: pointer;
}
.page {
  padding: 10px 20px;
  float: right;
}
.thumbnail {
  width: 140px;
  height: 80px;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 20px;
}
</style>
